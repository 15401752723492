/* eslint-disable import/no-duplicates */
// import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { html, render } from "lit-html";
// import { StartScreen } from "../shared/components";
import "../shared/components";
import { StartScreen } from "../shared/components";
import { clearScreen } from "../shared/util";

// const appInsights = new ApplicationInsights({
//   config: {
//     connectionString:
//       "InstrumentationKey=dafc02c2-4230-403f-b0c0-f1d4467b4c38;IngestionEndpoint=https://westeurope-5.in.applicationinsights.azure.com/;LiveEndpoint=https://westeurope.livediagnostics.monitor.azure.com/",
//   },
// });
// appInsights.loadAppInsights();
// appInsights.trackPageView(); // Manually call trackPageView to establish the current user/session/pageview

// let dataToWrite = {};

// const addData = (data: { key: string; val: string }) => {
//   dataToWrite = { ...data, ...dataToWrite };
// };

// const onDataAdded = (event: InputEvent, field: string) => {
//   if (event.data) addData({ key: field, val: event.data });
// };

// const writeTag = () => {
//   console.log(`tag written ${dataToWrite}`);
// };

// async function initializeWriter() {
//   render(EditableCardList((onChange = addData)), document.body);
// }

const buttonToReaderTitle = "Reader ➜";
const buttonToReaderUrl = "https://nfcreader.app";

const initializeWriter = () => {
  render(
    html`<app-switcher
        title=${buttonToReaderTitle}
        url=${buttonToReaderUrl}
      ></app-switcher
      ><editable-card-list></editable-card-list>`,
    document.body
  );
};
//
const clickHandler = {
  handleEvent() {
    initializeWriter();
  },
  capture: true,
};

(async () => {
  clearScreen();
  if (!("NDEFReader" in window)) {
    render(
      html`<app-switcher
          title=${buttonToReaderTitle}
          url=${buttonToReaderUrl}
        ></app-switcher>
        ${StartScreen({
          icon: "Sad",
          text: "Your device does not support NFC",
        })}`,
      document.body
    );
    return null;
  }
  const permissionName = "nfc" as PermissionName;
  const nfcPermissionStatus = await navigator.permissions.query({
    name: permissionName,
  });
  initializeWriter();
  if (nfcPermissionStatus.state === "granted") {
    // NFC access was previously granted, so we can start NFC scanning now.
    initializeWriter();
  } else {
    render(
      html` <app-switcher
          title=${buttonToReaderTitle}
          url=${buttonToReaderUrl}
        ></app-switcher>
        <span @click=${clickHandler}>
          ${StartScreen({
            icon: "Finger",
            text: "Tap the screen to start scanning",
          })}
        </span>`,
      document.body
    );
  }
  return null;
})();

// (async () => {
//   clearScreen();
//   initializeWriter();
//   return null;
// })();
